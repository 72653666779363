@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('fonts.css');
@layer base {
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Manrope', sans-serif;
  }
  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }
  body {
    font-size: 16px;
    width: calc(100vw - 17px) !important;
    margin: 0;
    padding: 0 !important;
  }
  @media screen and (max-width: 768px) {
    body {
      width: 100% !important;
    }
  }
}

@import 'react-toastify/dist/ReactToastify.css';

*::-webkit-scrollbar {
  border-radius: 20px; /* закругления плашки */
  width: 6px; /* ширина scrollbar */
}

*::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.3); /* цвет дорожки */
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(88, 88, 88, 0.5); /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #c00000; /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
}

.white-scroll::-webkit-scrollbar {
  border-radius: 20px; /* закругления плашки */
  width: 6px; /* ширина scrollbar */
}

.white-scroll::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.3); /* цвет дорожки */
}

.white-scroll::-webkit-scrollbar-thumb {
  width: 2px !important;
  background-color: #fff; /* цвет плашки */
  border-radius: 10px; /* закругления плашки */
}

.think-scroll::-webkit-scrollbar {
  border-radius: 20px; /* закругления плашки */
  width: 3px; /* ширина scrollbar */
}

.think-scroll::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.3); /* цвет дорожки */
}

.think-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(88, 88, 88, 0.5); /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
}
.think-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #c00000; /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
}

.layout {
  max-width: 1280px;
  margin: 0 auto;
  background: transparent;
  padding-left: 15px;
  padding-right: 15px;
}

.scroll-white::-webkit-scrollbar {
  border-radius: 20px;
  height: 6px;
}

.scroll-white::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.3); /* цвет дорожки */
  background-clip: content-box;
}

.scroll-white::-webkit-scrollbar-thumb {
  background-color: #fff; /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
  border: 2px solid transparent;
  background-clip: content-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#localvideo,
#videoElement,
#settings-video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
ol {
  list-style: decimal;
  padding-inline-start: 1.2rem;
}
ul {
  list-style: initial;
  padding-inline-start: 1.2rem;
}
input,
textarea {
  outline: none;
}

.hide-video-controls::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
  visibility: hidden !important;
}
.hide-video-controls::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
  visibility: hidden !important;
}
.hide-video-controls::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
  visibility: hidden !important;
}

.placeholder-grey > .ant-picker-input > input::placeholder {
  color: #111026 !important;
}

.placeholder-grey:where(.css-dev-only-do-not-override-17w9pt0).ant-picker .ant-picker-suffix {
  color: #111026;
}

.hide-input-number-arrow::-webkit-outer-spin-button,
.hide-input-number-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.hide-input-number-arrow[type='number'] {
  -moz-appearance: textfield;
}
.hidden_scroll {
  overflow: hidden !important;
}
