@font-face {
  font-family: 'Manrope';
  src: url('/src/assets/fonts/Manrope-Light.ttf');
  font-weight: 300;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('/src/assets/fonts/Manrope-Regular.ttf');
  font-weight: 400;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('/src/assets/fonts/Manrope-Medium.ttf');
  font-weight: 500;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('/src/assets/fonts/Manrope-SemiBold.ttf');
  font-weight: 600;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: url('/src/assets/fonts/Manrope-Bold.ttf');
  font-weight: 700;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: 'Comfortaa';
  src: url('/src/assets/fonts/Comfortaa-Light.ttf');
  font-weight: 300;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: 'Comfortaa';
  src: url('/src/assets/fonts/Comfortaa-SemiBold.ttf');
  font-weight: 600;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: 'Comfortaa';
  src: url('/src/assets/fonts/Comfortaa-Bold.ttf');
  font-weight: 700;
  font-display: block;
  font-style: normal;
}